import ReactGA from 'react-ga';
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {GOOGLE_TAG_MANAGER_ID, GOOGLE_TRACKING_ID} from "config/config";
import TagManager from "react-gtm-module";

export default function GoogleAnalytics() {
    let {pathname} = useLocation();

    useEffect(() => {
        ReactGA.initialize(GOOGLE_TRACKING_ID);
        ReactGA.pageview(pathname);
        const tagManagerArgs = {
            gtmId: GOOGLE_TAG_MANAGER_ID
        }
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: 'MainPageView'
        });
        TagManager.dataLayer({
            dataLayer: {
                event: 'MainPageView',
            },
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [pathname]);
    return <></>
}
