import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useState} from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'
import {LINKS} from "../../constants";
export default function GalleryItems({items = []}) {
    let [isOpen, setIsOpen] = useState(false)
    let [current, setCurrent] = useState({})
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10 w-1" onClose={() => setIsOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">

                                <div key={current?.id} className="px-5 py-24 mx-auto flex flex-wrap max-w-full m-auto text-center">
                                    <Carousel
                                        showArrows={true}
                                        autoPlay={true}
                                        showThumbs={true}
                                        stopOnHover={true}
                                        infiniteLoop={true}
                                        showIndicators={true}
                                        interval={8500}
                                        transitionTime={1500}
                                    >
                                        {
                                            current?.attributes?.images?.data?.map((image, i) => {
                                                return <div key={i} className="w-full">
                                                    <img alt="gallery"
                                                         className="w-full object-cover h-full object-center block"
                                                         src={`${LINKS.IMAGE_BASE_URL}${image.attributes?.url}`}/>
                                                </div>
                                            })
                                        }
                                    </Carousel>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

        <div className="container px-5 py-24 mx-auto flex flex-wrap">
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-6">
                {
                    items.map(item =>
                        <div key={item.id} className="block cursor-pointer" onClick={() => {
                            setCurrent(item)
                            setIsOpen(true)
                        }}>
                            <img
                                alt="Signage"
                                src={`${LINKS.IMAGE_BASE_URL}${item.attributes.images.data[0].attributes.url}`}
                                className="h-96 w-full rounded-bl-3xl rounded-tr-3xl object-cover sm:h-64 lg:h-96"
                            />

                            <div className="mt-4 sm:flex sm:items-center sm:justify-center sm:gap-4">
                                <strong className="font-medium w-64">{item.attributes.name}</strong>

                                <span className="hidden sm:block sm:h-px sm:w-8 sm:bg-yellow-500"></span>

                                <p className="mt-0.5 opacity-50 sm:mt-0">{item.attributes.description}</p>
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    </>
}
