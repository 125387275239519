import {useEffect, useState} from "react";
import {ApiConsumer} from "../../api/ApiConsumer";
import {CMS_Routes} from "../../routes/cms-routes";
import {NavLink} from "react-router-dom";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {LINKS} from "../../constants";
import {Carousel} from "react-responsive-carousel";

export default function Banners() {
    const [banners, setBanners] = useState([])
    const [hover, setHover] = useState('')

    useEffect(() => {
        ApiConsumer.get(CMS_Routes.PAGES.BANNERS)
            .then(res => {
                setBanners(res.data.data)
            })
            .catch(err => console.error(err))
    }, [])

    return <>
        <Carousel
            showArrows={true}
            autoPlay={true}
            showThumbs={false}
            stopOnHover={false}
            infiniteLoop={true}
            showIndicators={true}
            interval={5000}
        >
            {
                banners.map(banner => {
                    return <div key={banner.id} className="relative flex-1 overflow-hidden pb-20"
                                onMouseEnter={() => setHover(banner.id)} onMouseLeave={() => setHover('')}>
                        <img
                            className={`object-cover w-full h-screen pb-40 -mb-80 hover:backdrop-blur-md transition duration-500 ease-in-out ${hover !== banner.id ? 'filter grayscale' : ''}`}
                            src={`${LINKS.IMAGE_BASE_URL}${banner?.attributes?.image?.data?.attributes?.url}`}
                            alt={banner?.attributes?.name}/>
                        <div className="absolute inset-0  -mb-20 bg-opacity-50">
                            <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
                                <h2 className="text-white text-lg font-bold sm:text-5xl mb-4">{banner.attributes.description}</h2>
                                <div className="flex mr-auto ml-auto w-2/6 p-2">
                                    <NavLink
                                        className="block w-3/5 lg:w-3/6 rounded border border-red-500 bg-red-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 text-center mr-4"
                                        to={'/services'}
                                    >
                                        Event Services
                                    </NavLink>

                                    <NavLink
                                        className="block w-3/5 lg:w-3/6 rounded border border-red-500 bg-red-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 text-center ml-auto"
                                        to={'/development'}
                                    >
                                        Development Services
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </Carousel>
    </>
}
