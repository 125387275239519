import ContactForm from "components/contact";
import Header from "components/header";
import {LatestEvent} from "../components/events/lastest";

export default function Contact() {
    return <>
        <Header title={'Get in Touch'}
                sub_title={'The company\'s mission is to provide innovative event management solutions that exceed client expectations and create memorable experiences for attendees.'}/>
        <ContactForm/>
        <LatestEvent/>
    </>
}
