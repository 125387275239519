import QuoteForm from "../components/quote/form";
import Header from "../components/header";

export default function Quote() {
    return <>
        <Header title={'Get a Quote'}
                sub_title={'Athimthende Events provides an extremely broad range of services and experience'}/>
        <QuoteForm/>
    </>
}
