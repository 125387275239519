import Services from "../components/services";
import Header from "../components/header";
import CTA from "../components/cta";
import {LatestEvent} from "../components/events/lastest";
import {useEffect, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {CMS_Routes} from "../routes/cms-routes";

export default function ServicesPage() {
    const [content, setContent] = useState({})

    useEffect(() => {
        ApiConsumer.get(CMS_Routes.PAGES.SERVICES)
            .then(res => {
                console.log(res.data.data.attributes?.banner?.data?.attributes?.url)
                setContent(res.data.data.attributes)
            })
            .catch(err => console.error(err))
    }, [])

    return <>
        <Header title={content?.title}
                image={content?.banner?.data?.attributes?.url}
                sub_title={content?.subtitle}/>
        <Services description={content?.content} services={content?.service}/>
        <CTA/>
        <LatestEvent/>
    </>
}
