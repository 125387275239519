import {Link} from "react-router-dom";
import {ApiConsumer} from "../../api/ApiConsumer";
import {CMS_Routes} from "../../routes/cms-routes";
import {useEffect, useState} from "react";
import {LINKS} from "../../constants";

export function LatestEvent() {
    const [event, setEvent] = useState({})

    useEffect(()=>{
        ApiConsumer.get(CMS_Routes.PAGES.EVENTS)
            .then(res => {
                setEvent(res.data.data[0])
            })
            .catch(err => console.error(err))
    },[])
    return <>
        <section className="py-16 bg-gray-50 xl:px-0 px-8">
            <div className="w-full max-w-6xl mx-auto">
                <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-slate-900 sm:text-center text-left">Start
                    Planning Your Next Event With Us</h2>
                <div className="w-full relative md:pr-48">
                    <img className="rounded-t-2xl md:rounded-2xl aspect-video object-cover"
                         src={`${LINKS.IMAGE_BASE_URL}${event?.attributes?.images?.data[0]?.attributes?.url}`}
                         alt="adventure destination"/>
                    <div
                        className="md:absolute relative lg:w-96 w-full md:max-w-md right-0 top-0 md:py-8 lg:py-12 h-full">
                        <div
                            className="w-full h-full flex flex-col items-start justify-between rounded-b-2xl md:rounded-2xl p-8 bg-white shadow-xl">
                            <div
                                className="bg-gray-50 mb-3 lg:flex flex md:hidden border border-gray-100 text-red-500 px-3.5 space-x-1 items-center py-1.5 text-xs rounded-full inline-block font-bold">
                                <svg className="w-3.5 -ml-1 h-3.5" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>
                                <span className="">Our Recent Event</span>
                            </div>
                            <div className="relative">
                                <h3 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-slate-800">{event?.attributes?.name}</h3>
                                <p className="text-gray-500 text-sm py-5 lg:py-3 lg:text-base">{event?.attributes?.description}</p>
                            </div>
                            <Link to="/quote"
                               className="bg-red-500 px-6 py-3 text-sm font-semibold rounded-full text-white">Get a Quote</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}
