import HEADER_IMAGE from 'assets/images/header.jpg'
import {LINKS} from "../../constants";

export default function Header({title, sub_title, image = ''}) {

    return <div className="px-6 pt-4 mx-auto">
        <section className="h-auto bg-white relative overflow-hidden w-max-7xl rounded-2xl h-80">
            <div className="relative bg-red-500">
                <div className="absolute inset-0">
                    <img
                        className="h-full w-full object-cover"
                        src={image !== '' ? `${LINKS.IMAGE_BASE_URL}${image}` : HEADER_IMAGE}
                        alt=""
                    />
                    <div className="absolute inset-0 bg-red-500 mix-blend-multiply" aria-hidden="true"/>
                </div>
                <div className="relative mx-auto py-12 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl text-center pt-0">{title}</h1>
                    <p className="mt-6 max-w-6xl mx-auto text-xl text-indigo-100 text-center">{sub_title}</p>
                </div>
            </div>
        </section>
    </div>
}
