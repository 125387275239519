import LOGO from 'assets/images/logo.png'
import {Dialog, Popover} from '@headlessui/react'
import {Bars3Icon} from '@heroicons/react/24/outline'
import {Link, NavLink} from "react-router-dom";
import {MAIN_MENU} from "routes/main-menu";
import {SUB_MENU_TYPE} from "../../constants";
import {AboutNavigation, ServicesNavigation} from "components/navigation/sub-navigation";
import ColourBorder from "../colour-border";
import {Menu, Transition} from '@headlessui/react'
import {Fragment, useState} from "react";

const NormalMenu = ({menu}) => {
    return <NavLink className={({isActive}) =>
        isActive ? "text-base font-medium text-red-500 hover:text-gray-900 border-b-2 border-red-500" : "text-base font-medium text-gray-500 hover:text-red-600"}
                    to={menu.link}>
        {menu.name}
    </NavLink>
}


export default function Navigation() {
    let [isOpen, setIsOpen] = useState(false)

    function MenuType(menu) {
        if (menu.type === SUB_MENU_TYPE.SERVICE)
            return <ServicesNavigation menu={menu}/>
        if (menu.type === SUB_MENU_TYPE.ABOUT)
            return <AboutNavigation menu={menu}/>
        return <NormalMenu menu={menu}/>
    }

    return <header className='sticky top-0 z-20 shadow shadow-red-300'>
        <ColourBorder/>
        <Popover className="relative bg-white">
            <div
                className="container flex items-center justify-center h-full max-w-7xl px- mx-auto sm:justify-between xl:px-0 tails-selected-element relative z-30">

                <Link to="/" class="relative flex items-center inline-block h-14 h-full font-black leading-none">
                    <img
                        className="h-8 w-auto sm:h-12"
                        src={LOGO}
                        alt="Athimthende Events"
                    />
                </Link>

                <nav
                    className="absolute top-0 left-0 z-50 flex-col items-center justify-between hidden w-full h-56 pt-5 mt-24 text-sm text-gray-800 bg-white border-t border-gray-200 shadow-xl md:shadow-none md:flex md:w-auto md:flex-row md:h-16 lg:text-base md:bg-transparent md:mt-0 md:border-none md:py-0 md:relative">
                    <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
                        <Popover.Group as="nav" className="flex space-x-10">
                            {
                                MAIN_MENU.map(menu => {
                                    return MenuType(menu)
                                })
                            }
                        </Popover.Group>
                    </div>
                </nav>

                <div
                    className="absolute left-0 flex-col items-center justify-center hidden w-full pb-8 mt-48 border-b border-gray-200 md:relative md:w-auto md:bg-transparent md:border-none md:mt-0 md:flex-row md:p-0 md:items-end md:flex md:justify-between">

                    <div className="flex items-center">
                        <NavLink
                            to="contact"
                            className="ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-900"
                        >
                            Contact Us
                        </NavLink>
                    </div>
                </div>

                <div
                    className="absolute top-0 right-0 z-50 block w-full mt-8 m-auto cursor-pointer select-none md:hidden sm:mt-10">
                    <Bars3Icon className='h-12 text-red-400 -mt-8 float-right' onClick={() => setIsOpen(!isOpen)}/>

                </div>

            </div>
        </Popover>

        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10 w-1" onClose={() => setIsOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">

                                <div className="p-4">
                                    {MAIN_MENU.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                        >
                                            <div className="flex-auto">
                                                <a href={item.link} className="block font-semibold text-gray-900">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                    <div
                                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                                        <div className="flex-auto">
                                            <a href='/contact' className="block font-semibold text-gray-900">
                                                Contact Us
                                                <span className="absolute inset-0" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    </header>
}
