const BASE_URL = 'https://content.re-gmdlalose.co.za/api'
export const CMS_Routes = {
    PAGES: {
        PAGES: `${BASE_URL}/pages`,
        ABOUT: `${BASE_URL}/pages/1?populate=*`,
        SERVICES: `${BASE_URL}/pages/2?populate=*`,
        DEVELOPMENT_SERVICES: `${BASE_URL}/pages/4?populate=*`,
        EVENT_HIRE: `${BASE_URL}/pages/3?populate=*`,
        PILLARS: `${BASE_URL}/pillars?populate=*`,
        BANNERS: `${BASE_URL}/banners?populate=*`,
        EQUIPMENT: `${BASE_URL}/equipments?populate=*`,
        EVENTS: `${BASE_URL}/events?populate=*`,
        POPI: `${BASE_URL}/pages/5`,
        TERMS: `${BASE_URL}/pages/4`,
        FAQS: `${BASE_URL}/faqs`,
        MEDIA: `${BASE_URL}/newsletters?_sort=created_at:DESC`,
    },

    CONTACT: {
        FORM: `https://emails.content.sombiziholdings.co.za/contact`
    }
}
