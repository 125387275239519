import ReactMarkdown from "react-markdown";
import {useEffect, useState} from "react";
import {LINKS} from "../../constants";

export function AboutContent({content, pillars=[]}) {
    const [images, setImages] = useState([])

    useEffect(() => {
        setImages(content.quality_images?.data ?? [])
    }, [content])

    return <div className="relative bg-white py-8 sm:py-8">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
            <div className="relative sm:py-16 lg:py-0">
                <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                    <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72"/>
                    <svg
                        className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                        width={404}
                        height={392}
                        fill="none"
                        viewBox="0 0 404 392"
                    >
                        <defs>
                            <pattern
                                id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-red-500" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"/>
                    </svg>
                </div>
                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                    <div className="relative overflow-hidden rounded-2xl pt-64 pb-10 shadow-xl">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src={`${LINKS.IMAGE_BASE_URL}${content?.image?.data?.attributes?.url}`}
                            alt=""
                        />
                        <div className="absolute inset-0 bg-red-500 mix-blend-multiply"/>
                        <div className="absolute inset-0 bg-gradient-to-t from-red-500 via-red-500 opacity-40"/>
                        <div className="relative px-8">
                            <blockquote className="mt-8">
                                <div className="relative text-lg font-medium text-white md:flex-grow">
                                    <svg
                                        className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-red-500"
                                        fill="currentColor"
                                        viewBox="0 0 32 32"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
                                    </svg>
                                    <p className="relative">
                                        {content?.quote}
                                    </p>
                                </div>

                                <footer className="mt-4">
                                    <p className="text-base font-semibold text-white">CEO at Re-G Mdlalose</p>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                <div className="pt-12 sm:pt-16 lg:pt-20">
                    <div className="prose prose-red mx-auto mt-6 text-gray-500">
                        <p className="text-base leading-7">
                            <ReactMarkdown>{content?.content}</ReactMarkdown>
                        </p>
                    </div>
                </div>
            </div>
        </div>


        <div className="lg:mx-auto lg:max-w-7xl">
            <section>
                <div className="relative px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
                    <div className="relative mx-auto max-w-7xl">
                        <h3 className='text-center mb-8 -mt-8 text-2xl font-semibold text-neutral-600'>How we help you
                            make a lasting impression</h3>
                        <div className="grid max-w-lg gap-5 mx-auto lg:grid-cols-4 lg:max-w-none">
                            {
                                pillars?.map((block_content, i) => {
                                    return <div key={block_content.attributes.id}
                                                className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                        <div className="flex-shrink-0">
                                            <img className="object-cover w-full h-48"
                                                 src={`${LINKS.IMAGE_BASE_URL}${block_content.attributes.image.data?.attributes?.url}`}
                                                 alt=""/>
                                        </div>
                                        <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                                            <div className="flex-1">
                                                <a href="#" className="block mt-2">
                                                    <p class="text-xl font-semibold text-neutral-600 text-center">{block_content.attributes.name}</p>
                                                    <p className="mt-3 text-base text-gray-500">{block_content.attributes.description}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
}
