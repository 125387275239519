import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "pages/home";
import PageView from "components/page-view";
import GoogleAnalytics from "components/google-analytics";
import Navigation from "components/navigation";
import Footer from "components/footer";
import Contact from "pages/contact";
import About from "./pages/about";
import ServicesPage from "./pages/services";
import EventHire from "./pages/event-hire";
import Quote from "./pages/quote";
import Gallery from "./pages/gallery";
import DevelopmentServicesPage from "./pages/development-services";

export default function App() {
    return <>
        <Router>
            <PageView/>
            <GoogleAnalytics/>
            <Navigation/>
            <Routes>
                <Route exact path='/' element={<Home/>}/>
                <Route exact path='/about' element={<About/>}/>
                <Route exact path='/contact' element={<Contact/>}/>
                <Route exact path='/development' element={<DevelopmentServicesPage/>}/>
                <Route exact path='/services' element={<ServicesPage/>}/>
                <Route exact path='/event-hire' element={<EventHire/>}/>
                <Route exact path='/quote' element={<Quote/>}/>
                <Route exact path='/gallery' element={<Gallery/>}/>
            </Routes>
            <Footer/>
        </Router>
    </>
}
