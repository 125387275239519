import {useState} from "react";
import ANIMATION from 'assets/images/cta-image.jpg'
import {useNavigate} from "react-router-dom";

export default function CTA() {
    const [email, setEmail] = useState('')
    const navigate = useNavigate();

    return <>
        <section className="w-full py-16 bg-red-500">
            <div className="max-w-7xl px-10 mx-auto flex lg:flex-row flex-col items-center">
                <div className="lg:max-w-none max-w-lg lg:w-1/2 relative">
                    <p className="px-3 py-2 uppercase tracking-widest text-xs text-red-500 bg-white inline rounded-full font-medium">
                        Book Your Event Now
                    </p>
                    <h1 className="text-5xl sm:text-6xl lg:text-7xl mt-6 font-extrabold text-white ">
                        Re-G Mdlalose
                    </h1>
                    <p className="text-red-100 sm:text-lg lg:max-w-md mt-7">Re- G Mdlalose (Pty) Ltd has built strong
                        relationships with many of its clients, based on trust, transparency, and exceptional
                        service.</p>
                    <div
                        className="bg-black mt-7 bg-opacity-10 rounded-full relative flex items-center h-16 p-2 overflow-hidden lg:max-w-md">
                        <input type="email"
                               className="w-full h-full focus:outline-none bg-transparent placeholder-red-200 text-blue-100 pl-7 font-medium pr-5"
                               placeholder="Email Address"
                               value={email}
                               onChange={event => setEmail(event.target.value)}
                        />
                        <button
                            onClick={() => {
                                navigate(`/contact?email=${email}`)
                            }}
                            className="h-full px-8 text-red-400 bg-white flex-shrink-0 rounded-full font-medium text-lg">
                            Contact Us
                        </button>
                    </div>
                    <svg
                        className="fill-current text-white absolute right-0 top-0 w-48 h-48 -mt-8 opacity-10 -translate-x-12"
                        viewBox="0 0 198 198">
                        <g fill="currentColor" fill-rule="evenodd">
                            <path
                                d="M68.517 15.502a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M98.9 15.502a7.75 7.75 0 0 0 7.752-7.751A7.75 7.75 0 0 0 98.9 0a7.75 7.75 0 0 0-7.751 7.751 7.75 7.75 0 0 0 7.75 7.751M129.284 15.502a7.75 7.75 0 0 0 7.751-7.751A7.75 7.75 0 0 0 129.285 0a7.75 7.75 0 0 0-7.752 7.751 7.75 7.75 0 0 0 7.751 7.751M38.135 45.885a7.75 7.75 0 0 0 7.75-7.751 7.75 7.75 0 0 0-7.75-7.751 7.75 7.75 0 0 0-7.751 7.75 7.75 7.75 0 0 0 7.75 7.752M68.517 45.885a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M98.9 45.885a7.75 7.75 0 0 0 7.752-7.751 7.75 7.75 0 0 0-7.751-7.751 7.75 7.75 0 0 0-7.751 7.75 7.75 7.75 0 0 0 7.75 7.752M129.284 45.885a7.75 7.75 0 0 0 7.751-7.751 7.75 7.75 0 0 0-7.75-7.751 7.75 7.75 0 0 0-7.752 7.75 7.75 7.75 0 0 0 7.751 7.752M159.667 45.885a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M7.751 76.268a7.75 7.75 0 0 0 7.751-7.75 7.75 7.75 0 0 0-7.751-7.752A7.75 7.75 0 0 0 0 68.517a7.75 7.75 0 0 0 7.751 7.751M38.135 76.268a7.75 7.75 0 0 0 7.75-7.75 7.75 7.75 0 0 0-7.75-7.752 7.75 7.75 0 0 0-7.751 7.751 7.75 7.75 0 0 0 7.75 7.751M68.517 76.268a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M98.9 76.268a7.75 7.75 0 0 0 7.752-7.75 7.75 7.75 0 0 0-7.751-7.752 7.75 7.75 0 0 0-7.751 7.751 7.75 7.75 0 0 0 7.75 7.751M129.284 76.268a7.75 7.75 0 0 0 7.751-7.75 7.75 7.75 0 0 0-7.75-7.752 7.75 7.75 0 0 0-7.752 7.751 7.75 7.75 0 0 0 7.751 7.751M159.667 76.268a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503"></path>
                            <g transform="translate(182.3 60.766)">
                                <path
                                    d="M7.751 15.502a7.75 7.75 0 0 0 7.751-7.751A7.75 7.75 0 0 0 7.751 0 7.75 7.75 0 0 0 0 7.751a7.75 7.75 0 0 0 7.751 7.751"></path>
                            </g>
                            <path
                                d="M7.751 106.652a7.75 7.75 0 0 0 7.751-7.751 7.75 7.75 0 0 0-7.751-7.751A7.75 7.75 0 0 0 0 98.9a7.75 7.75 0 0 0 7.751 7.752M38.135 106.652a7.75 7.75 0 0 0 7.75-7.751 7.75 7.75 0 0 0-7.75-7.751 7.75 7.75 0 0 0-7.751 7.75 7.75 7.75 0 0 0 7.75 7.752M68.517 106.652a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M98.9 106.652a7.75 7.75 0 0 0 7.752-7.751A7.75 7.75 0 0 0 98.9 91.15a7.75 7.75 0 0 0-7.751 7.75 7.75 7.75 0 0 0 7.75 7.752M129.284 106.652a7.75 7.75 0 0 0 7.751-7.751 7.75 7.75 0 0 0-7.75-7.751 7.75 7.75 0 0 0-7.752 7.75 7.75 7.75 0 0 0 7.751 7.752M159.667 106.652a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503"></path>
                            <g transform="translate(182.3 91.15)">
                                <path
                                    d="M7.751 15.502a7.75 7.75 0 0 0 7.751-7.751A7.75 7.75 0 0 0 7.751 0 7.75 7.75 0 0 0 0 7.751a7.75 7.75 0 0 0 7.751 7.751"></path>
                            </g>
                            <path
                                d="M7.751 137.035a7.75 7.75 0 0 0 7.751-7.751 7.75 7.75 0 0 0-7.751-7.751A7.75 7.75 0 0 0 0 129.283a7.75 7.75 0 0 0 7.751 7.752M38.135 137.035a7.75 7.75 0 0 0 7.75-7.751 7.75 7.75 0 0 0-7.75-7.751 7.75 7.75 0 0 0-7.751 7.75 7.75 7.75 0 0 0 7.75 7.752M68.517 137.035a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M98.9 137.035a7.75 7.75 0 0 0 7.752-7.751 7.75 7.75 0 0 0-7.751-7.751 7.75 7.75 0 0 0-7.751 7.75 7.75 7.75 0 0 0 7.75 7.752M129.284 137.035a7.75 7.75 0 0 0 7.751-7.751 7.75 7.75 0 0 0-7.75-7.751 7.75 7.75 0 0 0-7.752 7.75 7.75 7.75 0 0 0 7.751 7.752M159.667 137.035a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503"></path>
                            <g transform="translate(182.3 121.533)">
                                <path
                                    d="M7.751 15.502a7.75 7.75 0 0 0 7.751-7.751A7.75 7.75 0 0 0 7.751 0 7.75 7.75 0 0 0 0 7.751a7.75 7.75 0 0 0 7.751 7.751"></path>
                            </g>
                            <path
                                d="M38.135 167.418a7.75 7.75 0 0 0 7.75-7.75 7.75 7.75 0 0 0-7.75-7.752 7.75 7.75 0 0 0-7.751 7.751 7.75 7.75 0 0 0 7.75 7.751M68.517 167.418a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M98.9 167.418a7.75 7.75 0 0 0 7.752-7.75 7.75 7.75 0 0 0-7.751-7.752 7.75 7.75 0 0 0-7.751 7.751 7.75 7.75 0 0 0 7.75 7.751M129.284 167.418a7.75 7.75 0 0 0 7.751-7.75 7.75 7.75 0 0 0-7.75-7.752 7.75 7.75 0 0 0-7.752 7.751 7.75 7.75 0 0 0 7.751 7.751M159.667 167.418a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M68.517 197.802a7.752 7.752 0 1 0 .001-15.503 7.752 7.752 0 0 0 0 15.503M98.9 197.802a7.75 7.75 0 0 0 7.752-7.751A7.75 7.75 0 0 0 98.9 182.3a7.75 7.75 0 0 0-7.751 7.75 7.75 7.75 0 0 0 7.75 7.752M129.284 197.802a7.75 7.75 0 0 0 7.751-7.751 7.75 7.75 0 0 0-7.75-7.751 7.75 7.75 0 0 0-7.752 7.75 7.75 7.75 0 0 0 7.751 7.752"></path>
                        </g>
                    </svg>
                </div>
                <div className="lg:max-w-none max-w-lg lg:w-1/2 lg:mt-0 mt-14">
                    <img src={ANIMATION} class="w-full rounded-xl opacity-80"/>
                </div>
            </div>
        </section>
    </>
}
