import {useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import {CMS_Routes} from "routes/cms-routes";
import {useSearchParams} from "react-router-dom";

export default function ContactForm() {
    const [details, setDetails] = useState({subject: 'Contact Message'})
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    function sendMessage() {
        setLoading(true)
        ApiConsumer.post(CMS_Routes.CONTACT.FORM, details)
            .then(() => {
                setSent(true)
            })
            .catch(err => {
                setSent(false)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setDetails((prevState) => ({
            ...prevState,
            [`email`]: searchParams.get("email"),
        }))
    }, [])


    return <>
        <section className="text-gray-600 body-font relative">

            <div className="container px-5 py-12 mx-auto flex sm:flex-nowrap flex-wrap">
                <div
                    className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                    <iframe className="absolute inset-0"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3458.0881676305885!2d30.944613615569928!3d-29.919363832431408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef7aba18fed46ef%3A0x5ceccc561f358849!2s5%20Siskin%20Pl%2C%20Woodhaven%2C%20Durban%2C%204163!5e0!3m2!1sen!2sza!4v1681293580171!5m2!1sen!2sza"
                            width="100%" height="100%" frameBorder="0"></iframe>
                </div>
                {
                    sent ?
                        <div
                            className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-4 mt-2 md:mt-0 h-96">
                            <h5 className='tx-success mt-5 mb-5 text-center col-span-2 pt-5'>
                                <strong>Submitted Successfully.</strong><br/>
                                Thank You. Our friendly staff will review your Enquiry/Request and get back
                                to you.
                            </h5>
                        </div> :
                        <form
                            className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-4 mt-2 md:mt-0"
                            method="POST"
                            onSubmit={event => {
                                event.preventDefault()
                                sendMessage()
                            }}>
                            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Get in Touch</h2>
                            <div className="relative mb-4">
                                <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
                                <input type="text" id="name" name="name"
                                       onChange={event => {
                                           setDetails((prevState) => ({
                                               ...prevState,
                                               [`name`]: event.target.value,
                                           }))
                                       }}
                                       value={details?.name}
                                       className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                            </div>
                            <div className="relative mb-4">
                                <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                                <input type="email" id="email" name="email"
                                       onChange={event => {
                                           setDetails((prevState) => ({
                                               ...prevState,
                                               [`email`]: event.target.value,
                                           }))
                                       }}
                                       value={details?.email}
                                       className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                            </div>
                            <div className="relative mb-4">
                                <label htmlFor="phone" className="leading-7 text-sm text-gray-600">Phone</label>
                                <input type="text" id="phone" name="phone"
                                       onChange={event => {
                                           setDetails((prevState) => ({
                                               ...prevState,
                                               [`cellphone`]: event.target.value,
                                           }))
                                       }}
                                       value={details?.cellphone}
                                       className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                            </div>
                            <div className="relative mb-4">
                                <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                                <textarea id="message" name="message"
                                          onChange={event => {
                                              setDetails((prevState) => ({
                                                  ...prevState,
                                                  [`message`]: event.target.value,
                                              }))
                                          }}
                                          value={details?.message}
                                          className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                            </div>
                            <button type='submit'
                                    className="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-800 rounded text-lg">
                                Submit
                            </button>
                        </form>
                }
            </div>

            <div className="container px-5 mx-auto w-full">
                <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                    <div className="lg:w-1/3 px-6 mt-4 lg:mt-0">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                        <p className="mt-1">5 Siskin Place, Woodhaven, Durban 4004</p>
                    </div>
                    <div className="lg:w-1/3 px-6 mt-4 lg:mt-0">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                        <a href='mailto:info@re-gmdlalose.co.za' className="text-indigo-500 leading-relaxed">info@re-gmdlalose.co.za</a>
                    </div>
                    <div className="lg:w-1/3 px-6 mt-4 lg:mt-0">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">PHONE</h2>
                        <p className="leading-relaxed">071 296 5111</p>
                    </div>
                </div>
            </div>
        </section>
    </>
}
