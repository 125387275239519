import React from "react";
import {createRoot} from 'react-dom/client';
import "index.css";
import App from "App";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import reportWebVitals from "reportWebVitals";

if (process.env.NODE_ENV === 'production') console.log = function () {
};
if (process.env.NODE_ENV !== 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [new BrowserTracing()],
        environment: process.env.NODE_ENV,
        tracesSampleRate: 1.0,
    });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App tab="app"/>);

reportWebVitals();
