export default function ColourBorder(){
    return <>
        <div className="flex">
            <div className="flex-1 h-1.5 bg-red-500"></div>
            <div className="flex-1 h-1.5 bg-red-500"></div>
            <div className="flex-1 h-1.5 bg-red-500"></div>
            <div className="flex-1 h-1.5 bg-red-500"></div>
        </div>
    </>
}
