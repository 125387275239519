import Header from "../components/header";
import {AboutContent} from "../components/content/about";
import {useEffect, useState} from "react";
import {LatestEvent} from "../components/events/lastest";
import {ApiConsumer} from "../api/ApiConsumer";
import {CMS_Routes} from "../routes/cms-routes";

export default function About() {
    const [content, setContent] = useState({})
    const [pillars, setPillars] = useState([])

    function fetch() {
        console.log(CMS_Routes.PAGES.ABOUT)
        ApiConsumer.get(CMS_Routes.PAGES.PILLARS)
            .then(res => {
                setPillars(res.data.data)
            })
            .catch(err => console.error(err))

        ApiConsumer.get(CMS_Routes.PAGES.ABOUT)
            .then(res => {
                setContent(res.data.data.attributes)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetch()
    }, [])
    return <>
        <Header title={content.title}
                image={content?.banner?.data?.attributes?.url}
                sub_title={content.subtitle}/>
        <AboutContent content={content} pillars={pillars}/>
        <LatestEvent/>
    </>
}
