import Header from "../components/header";
import {LatestEvent} from "../components/events/lastest";
import GalleryItems from "../components/gallery/gallery-items";
import {useEffect, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {CMS_Routes} from "../routes/cms-routes";

export default function Gallery() {
    const [gallery, setGallery] = useState([])

    useEffect(()=>{
        ApiConsumer.get(CMS_Routes.PAGES.EVENTS)
            .then(res => {
                setGallery(res.data.data)
            })
            .catch(err => console.error(err))
    },[])

    return <>
        <Header title={'Our Gallery'}
                sub_title={'Athimthende Events provides an extremely broad range of services and experience'}/>
        <GalleryItems items={gallery}/>
        <LatestEvent/>
    </>
}
