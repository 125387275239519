import ReadMoreReact from 'read-more-react';

export default function Services({description, services = []}) {

    return <>
        <section className="relative py-20 bg-gray-50 tails-selected-element" contentEditable="true">
            <div className="relative px-10 mx-auto max-w-7xl lg:px-16">
                <div className="max-w-5xl mx-auto mb-16 text-center lg:mb-12">
                    <p className="mb-2 text-xl text-gray-400">{description}</p>
                </div>
                <div className="md:grid md:grid-cols-2 lg:grid-cols-4 gap-x-4">
                    {
                        services.map(service => {
                            return <div key={service.id}
                                        className="col-span-1 p-10 bg-white border-t-4 border-red-500 mb-6 shadow shadow-red-500 cursor-pointer">
                                <div className="flex flex-col pb-8 border-b border-gray-200">
                                    <h4 className="mt-1 text-xl font-medium">{service.name}</h4>
                                    <p className="mt-4 text-gray-500">
                                        {
                                            service?.description !== null ?
                                                <ReadMoreReact text={service.description}
                                                               min={1}
                                                               ideal={50}
                                                               max={50}
                                                               readMoreText={'Read more...'}/> : null
                                        }
                                    </p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>
    </>
}
