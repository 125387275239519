import {SUB_MENU_TYPE} from "../constants";

export const MAIN_MENU = [
    {
        name: 'Home',
        link: '/',
    },
    {
        name: 'About Us',
        link: 'about',
        multi: false,
        type: SUB_MENU_TYPE.SIMPLE,

    },
    {
        name: 'Development Services',
        link: 'development',
        multi: false,
        type: SUB_MENU_TYPE.SIMPLE
    },
    {
        name: 'Events Services',
        link: 'services',
        multi: false,
        type: SUB_MENU_TYPE.SIMPLE
    },
    {
        name: 'Gallery',
        link: 'gallery',
        multi: false,
        type: SUB_MENU_TYPE.SIMPLE
    }
]
