import {Link} from "react-router-dom";

export default function QuoteForm() {
    return <>
        <div className="py-10 bg-white md:py-16 tails-selected-element" contentEditable="true">
            <div className="px-10 mx-auto max-w-7xl md:px-16">
                <div className="max-w-3xl mx-auto mb-10 md:mb-16">
                    <h2 className="mt-1 text-2xl font-bold text-left text-gray-600 lg:text-3xl md:mt-2">
                        Need to get a quote?
                    </h2>
                    <p className="max-w-screen-md mx-auto mt-4 text-left text-gray-500 md:text-lg md:mt-6">
                        Fill out the form below and we'll get back to you with pricing within 24-48
                        hours. If you do not have all the info, no problem. Just <Link
                        className='cursor-pointer text-red-500' to='/contact'>contact us here</Link>.
                    </p>
                </div>
                <form className="grid max-w-3xl gap-4 mx-auto sm:grid-cols-2">
                    <div className="">
                        <label htmlFor="first-name"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">
                            First name
                        </label>
                        <input name="first-name"
                               className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"/>
                        <div data-lastpass-icon-root="true"></div>
                    </div>

                    <div>
                        <label htmlFor="last-name"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">
                            Last name
                        </label>
                        <input name="last-name"
                               className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"/>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="company"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Company</label>
                        <input name="company"
                               className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"/>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="email"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Email</label>
                        <input name="email"
                               className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"/>
                    </div>

                    <div>
                        <label htmlFor="subject"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Event
                            Name</label>
                        <input name="subject"
                               className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"/>
                    </div>
                    <div>
                        <label htmlFor="subject"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">How many
                            people do you expect?</label>
                        <input name="subject" type='number'
                               className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"/>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="subject"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Event
                            Location</label>
                        <input name="subject"
                               className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"/>
                    </div>

                    <div>
                        <label htmlFor="subject"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Event
                            Setting</label>
                        <select name="setting" id="setting"
                                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300">
                            <option value="indoor">Indoor Event</option>
                            <option value="outdoor">Outdoor Event</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="subject"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Event
                            Stage</label>

                        <select name="stage" id="stage"
                                className="w-full px-3 py-2 text-gray-800 border rounded-md outline-none bg-gray-50">
                            <option value="indoor">Open Stage</option>
                            <option value="outdoor">Roof Stage</option>
                        </select>
                    </div>


                    <div className="sm:col-span-2">
                        <label htmlFor="message"
                               className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base">Extra
                            Information</label>
                        <textarea name="message"
                                  className="w-full h-64 px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"></textarea>
                    </div>

                    <div className="flex items-center justify-between sm:col-span-2 ml-auto">
                        <button
                            className="w-52 inline-block px-8 py-3 text-sm font-semibold text-center text-white transition duration-100 bg-red-500 rounded-md outline-none hover:bg-red-500 active:bg-red-700 ring-red-300 md:text-base">
                            Submit
                        </button>
                    </div>
                </form>
                <p className="max-w-3xl mx-auto mt-5 text-xs text-gray-400">
                    Please allow up to 24-48 hour response during the weekdays.
                </p>
            </div>
        </div>
    </>
}
