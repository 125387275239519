import Banners from "components/banners";
import {FeaturedEquipment} from "../components/featured/equipment";
import CTA from "../components/cta";
import {LatestEvent} from "../components/events/lastest";

export default function Home() {
    return <>
        <Banners/>
        <LatestEvent/>
        <CTA/>

    </>
}
